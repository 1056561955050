export class ServerError extends Error {
  status?: number;

  constructor(message: string, status?: number) {
    super(message);
    this.status = status;
  }
}

/**
 * 認証エラー
 */
export class UnauthenticatedError extends ServerError {
  constructor(message = 'Not authenticated', status = 401) {
    super(message, status);
  }
}

/**
 * アクセス拒否エラー
 */
export class ForbiddenError extends ServerError {
  constructor(message = 'Forbidden', status = 403) {
    super(message, status);
  }
}

/**
 * 競合エラー
 */
export class NotUniqueError extends ServerError {
  constructor(message = 'Not unique', status = 409) {
    super(message, status);
  }
}

/**
 * CSRFエラー
 */
export class UnprocessableEntityError extends ServerError {
  constructor(message = 'Unprocessable entity', status = 422) {
    super(message, status);
  }
}

/**
 * サーバエラー
 */
export class InternalServerError extends ServerError {
  constructor(message = 'Internal server error', status = 500) {
    super(message, status);
  }
}