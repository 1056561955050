import originalAxios from 'axios';

export const csrfToken = (): string => {
  const meta = document.querySelector('meta[name=csrf-token]') as HTMLMetaElement | undefined;
  return meta?.content ?? '';
};

export const myAxios = originalAxios.create({
  timeout: 5000,
  headers: {
    'X-CSRF-Token': csrfToken(),
  },
  withCredentials: true, // Cookieを送信するため
});

export const imageAxios = originalAxios.create({
  timeout: 300000,
  headers: {
    'X-CSRF-Token': csrfToken(),
  },
});
