import {
  useSharedToast
} from '../../hooks/useSharedToast';
import { Alert } from 'react-bootstrap';
import { Variant } from 'react-bootstrap/types';
import { css } from '@emotion/react';

export const Toast = () => {
  const { toastProperties, hideToast } = useSharedToast();

  return (
    <>
      <style>
        {`
          @keyframes anime {
            16.666% { transform: translateX(0); }
            83.333% { transform: translateX(0); }
          }
        `}
      </style>
      {toastProperties.map(({
        id,
        message: { title, message, type },
        className,
        ref
      }) => {
        let variant: Variant = 'info';
        const messages = Array.isArray(message) ? message : [message];
        switch (type) {
          case 'success':
            variant = 'success';
            break;
          case 'info':
            variant = 'info';
            break;
          case 'error':
            variant = 'danger';
            break;
        }
        return (
          <div
            key={id}
            className={className}
            ref={ref}
          >
            <Alert
              variant={variant}
              onClose={hideToast}
              dismissible={true}
            >
              {title && (<Alert.Heading>{title}</Alert.Heading>)}
              {messages.map((message, index) => (
                <p
                  key={index}
                  css={css`
                    margin: 2px 0;
                  `}>{message}</p>
              ))}
            </Alert>
          </div>
        );
      })}
    </>
  );
};
