import { useEffect } from 'react';
import {
  ToastMessage,
  ToastOption,
  useSharedToast
} from '../../hooks/useSharedToast';

type ShowToastProps = {
  toastMessage: ToastMessage;
  option?: Partial<ToastOption>,
}

export const ShowToast = ({ toastMessage, option }: ShowToastProps) => {
  const { showToast } = useSharedToast();

  useEffect(() => {
    showToast(toastMessage, option);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};
