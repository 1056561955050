import { withHttpError } from '../libs/httpRequest/withHttpError';
import {
  GetListResponse,
  GetOneResponse,
  GetShopsCountResponse
} from './api';
import { myAxios } from '../libs/httpRequest/myAxios';

export type Store = {
  id: number;
  name: string;
}

type Location = {
  x: number | null;
  y: number | null;
  z: number | null;
  m: number | null;
}

export type StoreDetails = Store & {
  phone_number: string | null;
  company_id: number | null;
  city_id: number,
  postal_code: string,
  address1: string | null;
  address2: string | null;
  address3: string | null;
  location: Location | null;
  code: string | null;
  kyokai_code: string;
  deletedAt: string | null;
  createdAt: string;
  updatedAt: string;
  search_keyword: string | null;
  status: string;
}

export type StoresRequestParams = {
  ids?: number[];
  company_id?: number;
  without_job_postings?: boolean;
  q?: string;
}

export type StoreRequestParams = {
  id: number;
}

export type StoresCountRequestParams = {
  keyword?: string[];
  prefecture_url_word?: string;
  city_code?: string[];
  job_occupation_url_words?: string[];
  job_type_url_words?: string[];
  job_employment_types?: string[];
  job_salary_type?: string;
  salary_min?: string;
  salary_max?: string;
  job_work_style_url_words?: string[];
  job_feature_url_words?: string[];
  job_benefit_url_words?: string[];
  railroad_line_ids?: number[];
  railroad_station_ids?: number[];
}

export const fetchStores = async (params?: StoresRequestParams): Promise<GetListResponse<Store>> => {
  const response = await withHttpError<GetListResponse<Store>>(async () => {
    return await myAxios.get('/api/v1/stores/get_stores', {
      params
    });
  });
  return response.data;
};

export const fetchStore = async (params?: StoreRequestParams): Promise<GetOneResponse<Store>> => {
  const response = await withHttpError<GetOneResponse<Store>>(async () => {
    return await myAxios.get('/api/v1/stores/get_store', {
      params
    });
  });
  return response.data;
};

export const fetchStoresCount = async (params?: StoresCountRequestParams): Promise<GetShopsCountResponse> => {
  const response = await withHttpError<GetShopsCountResponse>(async () => {
    return await myAxios.get('/api/v1/stores/get_stores_count', {
      params
    });
  });
  return response.data;
};
