import { withHttpError } from '../libs/httpRequest/withHttpError';
import {
  GetListResponse,
  GetOneResponse
} from './api';
import { myAxios } from '../libs/httpRequest/myAxios';

export type Prefecture = {
  id: number;
  name: string;
  urlWord: string;
}

export type PrefecturesRequestParams = {
  ids?: number[];
  q?: string;
  url_words?: string[];
}

export type PrefectureRequestParams = {
  id?: number;
  url_word?: string;
}

export const fetchPrefectures = async (params?: PrefecturesRequestParams): Promise<GetListResponse<Prefecture>> => {
  const response = await withHttpError<GetListResponse<Prefecture>>(async () => {
    return await myAxios.get('/api/v1/prefectures/get_prefectures', {
      params
    });
  });
  return response.data;
};

export const fetchPrefecture = async (params?: PrefectureRequestParams): Promise<GetOneResponse<Prefecture>> => {
  const response = await withHttpError<GetOneResponse<Prefecture>>(async () => {
    return await myAxios.get('/api/v1/prefectures/get_prefecture', {
      params
    });
  });
  return response.data;
};
