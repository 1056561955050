import {
  AxiosError,
  AxiosResponse
} from 'axios';
import {
  ForbiddenError,
  InternalServerError,
  NotUniqueError,
  UnauthenticatedError,
  UnprocessableEntityError
} from './httpErrors';

export type ApiResponseErrorData = {
  message: string;
  backtrace: string[];
  status: number;
}

export const withHttpError = async <T = any>(fn: () => Promise<AxiosResponse<T>>, onError?: () => void): Promise<AxiosResponse<T>> => {
  try {
    return await fn();
  } catch (e) {
    if (e instanceof AxiosError) {
      onError && onError();
      const response = e.response;
      if (response) {
        const data = response.data as ApiResponseErrorData;
        const message = data.message || undefined;
        switch (response.status) {
          case 401:
            throw new UnauthenticatedError(message);
          case 403:
            throw new ForbiddenError(message);
          case 409:
            throw new NotUniqueError(message);
          case 422:
            // CSRF Tokenエラー時
            throw new UnprocessableEntityError(message);
          default:
            // Internal Server Error
            throw new InternalServerError(message);
        }
      }
    }
    if (e instanceof Error) {
      throw new InternalServerError(e.message || undefined);
    }
    throw new InternalServerError('原因不明のエラーが発生しました');
  }
};
