import {
  css,
  keyframes,
} from '@emotion/css';
import {
  createRef,
  RefObject,
  useCallback,
  useState
} from 'react';
import { useBetween } from 'use-between';
import { v4 as uuidv4 } from 'uuid';
import classNames from 'classnames';

export type ToastType = 'success' | 'error' | 'info' | 'validationError';

export type ToastMessage = {
  title?: string;
  message: string | string[];
  type?: ToastType;
}

export type ToastOption = {
  duration: number;
  top: number;
  width: number;
}

type ToastProperty = {
  id: string;
  message: ToastMessage;
  className?: string;
  ref: RefObject<HTMLDivElement>;
};

const toastCss = ({ top, width }: ToastOption) => {
  return css`
    position: fixed;
    top: ${top}px;
    right: 10px;
    width: ${width}px;
    z-index: 9999;

    @media (max-width: 576px) {
      width: 300px;
    }
  `;
};

const showCss = ({ width }: ToastOption): string => {
  const showAnimation = keyframes`
    100% {
      transform: translateX(0);
    }
  `;

  return css`
    transform: translateX(${width + 20}px);
    animation: ${showAnimation} 0.5s ease 1 forwards;
    z-index: 9999;
  `;
};

const hideCss = ({ width }: ToastOption): string => {
  const hideAnimation = keyframes`
    100% {
      transform: translateX(${width + 20}px);
    }
  `;

  return css`
    transform: translateX(0);
    animation: ${hideAnimation} 0.4s ease 1 forwards;
    z-index: 9999;
  `;
};

const basicOption: ToastOption = {
  duration: 5000,
  top: 50,
  width: 400,
};

const validationOption: ToastOption = {
  duration: 15000,
  top: 50,
  width: 700,
};

const useToast = () => {
  const [toastProperties, setToastProperties] = useState<ToastProperty[]>([]);

  const showToast = useCallback((
    toastMessage: ToastMessage,
    option?: Partial<ToastOption>,
  ) => {
    const nextToastMessage = {
      ...(toastMessage.type === 'validationError' && {
        title: '入力に不備があります',
      }),
      ...toastMessage
    };

    // ループで呼ばれる場合にstateが更新されなくなるため、setStateの中で処理を記述している
    setToastProperties((toastProperties) => {
      const nextId = uuidv4();
      const nextOption: ToastOption = {
        ...(nextToastMessage.type === 'validationError' ? validationOption : basicOption),
        ...(option ?? {}),
      };

      // toastの位置を調整
      const lastToastProperty = toastProperties[toastProperties.length - 1];
      if (lastToastProperty?.ref.current) {
        nextOption.top = lastToastProperty.ref.current.offsetTop + lastToastProperty.ref.current.offsetHeight + 10;
      }

      const nextToastProperty: ToastProperty = {
        id: nextId,
        message: nextToastMessage,
        className: classNames(toastCss(nextOption), showCss(nextOption)),
        ref: createRef<HTMLDivElement>(),
      };

      // toastをアニメーションで隠す
      setTimeout(() => {
        nextToastProperty.ref.current?.classList.add(hideCss(nextOption));
      }, nextOption.duration);


      // toastのstateを削除
      setTimeout(() => {
        setToastProperties((toastProperties) =>
          toastProperties.filter(({ id }) => id !== nextId)
        );
      }, nextOption.duration + 200);

      return [...toastProperties, nextToastProperty];
    });
  }, []);

  const hideToast = useCallback((id: string) => {
    setToastProperties((toastProperties) =>
      toastProperties.filter(({ id: targetId }) => targetId !== id)
    );
  }, []);

  const hideAllToasts = useCallback(() => {
    setToastProperties([]);
  }, []);

  return {
    toastProperties,
    showToast,
    hideToast,
    hideAllToasts,
  };
};

export const useSharedToast = () => useBetween(useToast);
