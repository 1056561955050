import { withHttpError } from '../libs/httpRequest/withHttpError';
import {
  GetListResponse,
  GetOneResponse
} from './api';
import { myAxios } from '../libs/httpRequest/myAxios';

export type City = {
  id: number;
  code: number;
  url_word: string;
  name: string;
}

export type CitiesRequestParams = {
  ids?: number[];
  prefecture_id?: number;
  url_word?: string;
  code?: number;
  codes?: number[];
  q?: string;
}

export type CityRequestParams = {
  id?: number;
  code?: number;
  url_word?: string;
}

export const fetchCities = async (params?: CitiesRequestParams): Promise<GetListResponse<City>> => {
  const response = await withHttpError<GetListResponse<City>>(async () => {
    return await myAxios.get('/api/v1/cities/get_cities', {
      params
    });
  });
  return response.data;
};

export const fetchCity = async (params?: CityRequestParams): Promise<GetOneResponse<City>> => {
  const response = await withHttpError<GetOneResponse<City>>(async () => {
    return await myAxios.get('/api/v1/cities/get_city', {
      params
    });
  });
  return response.data;
};
